import {VtmnButton} from "@vtmn/react";
import Card from "../card/Card";
import {FormattedMessage} from "react-intl";

const FillType = ({handleChange}) => {

  return <>
    <h1 className="col-span-full vtmn-typo_display-2"><FormattedMessage id="form.fill.title"/></h1>
    <div className="col-span-4 small:col-span-3 small:col-start-2 medium:col-start-4">
      <Card type="water" noTitle>
        <VtmnButton
          className="min-w-full"
          size="stretched"
          name="ballast_type" value="water"
          onClick={handleChange}
        >
          <FormattedMessage id="form.fill.cta.water"/>
        </VtmnButton>
      </Card>
    </div>
    <div className="col-span-4 small:col-span-3">
      <Card type="sand" noTitle>
        <VtmnButton
          className="min-w-full"
          size="stretched"
          name="ballast_type" value="sand"
          onClick={handleChange}
        >
          <FormattedMessage id="form.fill.cta.sand"/>
        </VtmnButton>
      </Card>
    </div>
  </>
}

export default FillType;
