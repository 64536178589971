import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import 'typeface-roboto';
import 'typeface-roboto-condensed';
import './index.css';
import '@vtmn/css-utilities/dist/index.css';
import '@vtmn/icons/dist/vitamix/font/vitamix.css';
import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";
import Default from "./layouts/Default";
import Home from './views/Home';
import Form from "./views/Form";
import WrapperI18n from "./components/wrapper-i18n/WrapperI18n";

const root = ReactDOM.createRoot(document.getElementById('root'));

function ScrollToTopOnMount() {
  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <WrapperI18n>
        <ScrollToTopOnMount/>
        <Routes>
          <Route path="/" element={<Default/>}>
            <Route index element={<Home/>}/>
            <Route path="form" element={<Form/>}/>
          </Route>
        </Routes>
      </WrapperI18n>
    </BrowserRouter>
  </React.StrictMode>
);
