import {VtmnNavbar} from "@vtmn/react";
import {Outlet} from "react-router-dom";
import {ReactComponent as DecathlonLogo} from "../assets/decathlon-logo.svg";
import LangSwitcher from "../components/lang-switcher/LangSwitcher";

const Default = () => {
  return <>
    <div className="sticky top-0 left-0 right-0 drop-shadow z-10">
      <VtmnNavbar
        logoHref={"/"}
        logo={<DecathlonLogo/>}
        rightNav={<div className="-mt-6"><LangSwitcher/></div>}
      />
    </div>
    <Outlet/>
  </>;
}

export default Default;
