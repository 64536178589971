import {VtmnButton} from "@vtmn/react";
import Card from "../card/Card";
import {FormattedMessage} from "react-intl";

const TrampolineType = ({handleChange}) => {
  return <>
    <h1 className="col-span-full vtmn-typo_display-2"><FormattedMessage id="form.trampoline.title"/></h1>
    <div className="col-span-full tablet:col-span-3 tablet:col-start-2 small:col-span-2 small:col-start-2 medium:col-span-3 large:col-span-2 large:col-start-2">
      <Card type={240} noTitle>
        <VtmnButton
          className="min-w-full"
          size="stretched"
          name="trampoline_model" value="240"
          onClick={handleChange}
        >
          <FormattedMessage id="form.card.trampoline.240"/>
        </VtmnButton>
      </Card>
    </div>
    <div className="col-span-full tablet:col-span-3 small:col-span-2 small:col-start-auto medium:col-span-3 large:col-span-2">
      <Card type={300} noTitle>
        <VtmnButton
          className="min-w-full"
          size="stretched"
          name="trampoline_model" value="300"
          onClick={handleChange}
        >
          <FormattedMessage id="form.card.trampoline.300"/>
        </VtmnButton>
      </Card>
    </div>
    <div className="col-span-full tablet:col-span-3 tablet:col-start-2 small:col-span-2 small:col-start-auto medium:col-span-3 large:col-span-2">
      <Card type={365} noTitle>
        <VtmnButton
          className="min-w-full"
          size="stretched"
          name="trampoline_model" value="365"
          onClick={handleChange}
        >
          <FormattedMessage id="form.card.trampoline.365"/>
        </VtmnButton>
      </Card>
    </div>
    <div className="col-span-full tablet:col-span-3 small:col-span-2 small:col-start-3 medium:col-span-3 large:col-span-2">
      <Card type={420} noTitle>
        <VtmnButton
          className="min-w-full"
          size="stretched"
          name="trampoline_model" value="420"
          onClick={handleChange}
        >
          <FormattedMessage id="form.card.trampoline.420"/>
        </VtmnButton>
      </Card>
    </div>
    <div className="col-span-full tablet:col-span-3 tablet:col-start-2 small:col-span-2 small:col-start-auto medium:col-span-3 large:col-span-2">
      <Card type={520} noTitle>
        <VtmnButton
          className="min-w-full"
          size="stretched"
          name="trampoline_model" value="520"
          onClick={handleChange}
        >
          <FormattedMessage id="form.card.trampoline.520"/>
        </VtmnButton>
      </Card>
    </div>
  </>
}

export default TrampolineType;
