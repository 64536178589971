import ProductPicture from "../../assets/form/product.jpg";
import {VtmnButton, VtmnCard, VtmnIcon, VtmnTabs, VtmnTabsItem, VtmnTag} from "@vtmn/react";
import {FormattedMessage, useIntl} from "react-intl";
import {useState} from "react";

const Result = ({state}) => {
  const intl = useIntl();
  const [resultsOpened, setResultsOpened] = useState([true, false, false, false]);

  const winds = {
    vent_frais: {
      id: "vent_frais",
      name: intl.formatMessage({id:"form.result.winds.vent_frais.name"}),
      description: intl.formatMessage({id:"form.result.winds.vent_frais.description"}),
      speed: intl.formatMessage({id:"form.result.winds.vent_frais.speed"})
    },
    grand_vent: {
      id: "grand_vent",
      name: intl.formatMessage({id:"form.result.winds.grand_vent.name"}),
      description: intl.formatMessage({id:"form.result.winds.grand_vent.description"}),
      speed: intl.formatMessage({id:"form.result.winds.grand_vent.speed"})
    },
    coup_de_vent: {
      id: "coup_de_vent",
      name: intl.formatMessage({id:"form.result.winds.coup_de_vent.name"}),
      description: intl.formatMessage({id:"form.result.winds.coup_de_vent.description"}),
      speed: intl.formatMessage({id:"form.result.winds.coup_de_vent.speed"})
    },
    fort_coup_de_vent: {
      id: "fort_coup_de_vent",
      name: intl.formatMessage({id:"form.result.winds.fort_coup_de_vent.name"}),
      description: intl.formatMessage({id:"form.result.winds.fort_coup_de_vent.description"}),
      speed: intl.formatMessage({id:"form.result.winds.fort_coup_de_vent.speed"})
    },
    tempete: {
      id: "tempete",
      name: intl.formatMessage({id:"form.result.winds.tempete.name"}),
      description: intl.formatMessage({id:"form.result.winds.tempete.description"}),
      speed: intl.formatMessage({id:"form.result.winds.tempete.speed"})
    },
    violente_tempete: {
      id: "violente_tempete",
      name: intl.formatMessage({id:"form.result.winds.violente_tempete.name"}),
      description: intl.formatMessage({id:"form.result.winds.violente_tempete.description"}),
      speed: intl.formatMessage({id:"form.result.winds.violente_tempete.speed"})
    },
    ouragan: {
      id: "ouragan",
      name: intl.formatMessage({id:"form.result.winds.ouragan.name"}),
      description: intl.formatMessage({id:"form.result.winds.ouragan.description"}),
      speed: intl.formatMessage({id:"form.result.winds.ouragan.speed"})
    }
  }

  const unavailable = {unavailable: true};
  const oneBagPerFoot = intl.formatMessage({id: "form.result.winds.one_bag_per_foot"});
  const twoBagsPerFoot = intl.formatMessage({id: "form.result.winds.two_bags_per_foot"});
  const resultsMatrix = {
    240: {
      water: [
        {wind: winds.vent_frais, count: 3, countPerFoot: oneBagPerFoot, folded_posts: false},
        {wind: winds.fort_coup_de_vent, count: 3, countPerFoot: oneBagPerFoot, folded_posts: true},
        {wind: winds.coup_de_vent, count: 6, countPerFoot: twoBagsPerFoot, folded_posts: false},
        {wind: winds.tempete, count: 6, countPerFoot: twoBagsPerFoot, folded_posts: true}
      ],
      sand: [
        {wind: winds.grand_vent, count: 3, countPerFoot: oneBagPerFoot, folded_posts: false},
        {wind: winds.tempete, count: 3, countPerFoot: oneBagPerFoot, folded_posts: true},
        {wind: winds.coup_de_vent, count: 6, countPerFoot: twoBagsPerFoot, folded_posts: false},
        {wind: winds.violente_tempete, count: 6, countPerFoot: twoBagsPerFoot, folded_posts: true}
      ],
    },
    300: {
      water: [
        {wind: winds.vent_frais, count: 4, countPerFoot: oneBagPerFoot, folded_posts: false},
        {wind: winds.tempete, count: 4, countPerFoot: oneBagPerFoot, folded_posts: true},
        {wind: winds.coup_de_vent, count: 8, countPerFoot: twoBagsPerFoot, folded_posts: false},
        {wind: winds.violente_tempete, count: 8, countPerFoot: twoBagsPerFoot, folded_posts: true}
      ],
      sand: [
        {wind: winds.grand_vent, count: 4, countPerFoot: oneBagPerFoot, folded_posts: false},
        {wind: winds.violente_tempete, count: 4, countPerFoot: oneBagPerFoot, folded_posts: true},
        {wind: winds.coup_de_vent, count: 8, countPerFoot: twoBagsPerFoot, folded_posts: false},
        {wind: winds.ouragan, count: 8, countPerFoot: twoBagsPerFoot, folded_posts: true}
      ],
    },
    365: {
      water: [
        {wind: winds.vent_frais, count: 6, countPerFoot: oneBagPerFoot, folded_posts: false},
        unavailable,
        unavailable,
        unavailable,
      ],
      sand: [
        {wind: winds.grand_vent, count: 6, countPerFoot: oneBagPerFoot, folded_posts: false},
        unavailable,
        unavailable,
        unavailable,
      ],
    },
    420: {
      water: [
        {wind: winds.vent_frais, count: 5, countPerFoot: oneBagPerFoot, folded_posts: false},
        unavailable,
        unavailable,
        unavailable,
      ],
      sand: [
        {wind: winds.vent_frais, count: 5, countPerFoot: oneBagPerFoot, folded_posts: false},
        unavailable,
        unavailable,
        unavailable,
      ],
    },
    520: {
      water: [
        {wind: winds.vent_frais, count: 4, countPerFoot: oneBagPerFoot, folded_posts: false},
        {wind: winds.grand_vent, count: 8, countPerFoot: twoBagsPerFoot, folded_posts: false},
        unavailable,
        unavailable,
      ],
      sand: [
        {wind: winds.grand_vent, count: 4, countPerFoot: oneBagPerFoot, folded_posts: false},
        {wind: winds.coup_de_vent, count: 8, countPerFoot: twoBagsPerFoot, folded_posts: false},
        unavailable,
        unavailable,
      ],
    }
  };

  const results = resultsMatrix[state.trampoline_model][state.ballast_type];

  return <>
    <h1 className="col-span-full vtmn-typo_display-2"><FormattedMessage id="form.result.title"/></h1>

    <div className="col-span-full medium:col-span-6">
      <VtmnTabs align="start" size="medium">
        {results.map((result, i) => {
          let openState = new Array(4).fill(false);
          openState[i] = true;
          return <VtmnTabsItem key={"tabs" + i}
                               onClick={e => setResultsOpened(openState)}>
            <FormattedMessage
              id={result.unavailable ? "form.result.tabs.na" : (result.folded_posts ? "form.result.tabs.bags_folded_posts" : "form.result.tabs.bags_only")}
              values={{bagCount: result.count}}/>
          </VtmnTabsItem>;
        })}
      </VtmnTabs>
      <div className="p-4 text-lg">
        {results.map((result, i) => {
          if (result.unavailable) return <div key={"result" + i} style={{display: resultsOpened[i] ? "block" : "none"}}>
            <p><FormattedMessage id="form.result.tabs.na"/></p>
          </div>;

          return <div key={"result" + i} style={{display: resultsOpened[i] ? "block" : "none"}} className="space-y-4">
            <p>
              {!result.folded_posts ?
                <FormattedMessage id={"form.result.details.basic"} values={{
                  linebreak: <br/>,
                  em: chunks => <em>{chunks}</em>,
                  strong: chunks => <strong>{chunks}</strong>,
                  underline: chunks => <span className="underline">{chunks}</span>,
                  highlight: chunks => <span
                    className="bg-primary text-white font-bold text-xl uppercase">{chunks}</span>,
                  model: state.trampoline_model,
                  windName: result.wind.name.toLowerCase(),
                  bagCount: result.count,
                  bagPerFoot: result.countPerFoot,
                }}/>
                :
                <FormattedMessage id={"form.result.details.folded"} values={{
                  linebreak: <br/>,
                  em: chunks => <em>{chunks}</em>,
                  strong: chunks => <strong>{chunks}</strong>,
                  underline: chunks => <span className="underline">{chunks}</span>,
                  highlight: chunks => <span
                    className="bg-primary text-white font-bold text-xl uppercase">{chunks}</span>,
                  model: state.trampoline_model,
                  windName: result.wind.name.toLowerCase(),
                  bagCount: result.count,
                  bagPerFoot: result.countPerFoot,
                }}/>
              }
            </p>
            <p>
              <strong>
                <FormattedMessage id="form.result.details.wind.info" values={{
                  linebreak: <br/>,
                  windName: result.wind.name.toLowerCase(),
                  windDescription: result.wind.description
                }}/>
              </strong>
            </p>
            <p>
              <VtmnIcon value="windy-line" className="mr-2"/>
              <FormattedMessage id="form.result.details.wind.speed" values={{
                windSpeed: result.wind.speed,
                underline: chunks => <span className="underline">{chunks}</span>,
              }}/>
            </p>
          </div>;
        })}
      </div>
    </div>

    <div
      className="col-span-full tablet:col-start-2 tablet:col-span-6 medium:col-start-8 medium:col-span-4">
      <VtmnCard
        title={intl.formatMessage({id: "form.result.cta.product.title"})}
        variant="full-image"
        contentOpaque={false}
        fullImage={true}
        img={<img src={ProductPicture} alt={intl.formatMessage({id: "form.result.cta.product.title"})}/>}
      >
        <VtmnTag icon={"star-line"}><FormattedMessage id="form.result.cta.product.new"/></VtmnTag>
        <p><FormattedMessage id="form.result.cta.product.description"/></p>
        <VtmnButton
          variant={"primary-reversed"}
          iconRight="external-link-line"
          onClick={() => window.open(intl.formatMessage({id: "form.result.cta.product.link"}), "_blank").focus()}
        >
          <FormattedMessage id="form.result.cta.product.button"/>
        </VtmnButton>
      </VtmnCard>
    </div>
  </>;
}

export default Result;
