import {createContext, useMemo, useState} from "react";
import {IntlProvider} from "react-intl";
import languages from "../../lang/languages";

export const LocaleContext = createContext("fr");

const WrapperI18n = ({children}) => {
  const defaultLocale = (() => {
    for (let i = 0; i < navigator.languages.length; i++) {
      let lang = navigator.languages[i].slice(0, 2);
      if (languages.includes(lang)) {
        return lang;
      }
    }
    return "fr";
  })();

  const [locale, setLocale] = useState(defaultLocale);
  const [messages, setMessages] = useState(require(`./../../lang/${locale}.json`));

  const value = useMemo(() => ({locale, setLocale}), [locale]);
  useMemo(() => setMessages(require(`./../../lang/${locale}.json`)), [locale]);

  return <LocaleContext.Provider value={value}>
    <IntlProvider key={locale} locale={locale} messages={messages}>{children}</IntlProvider>
  </LocaleContext.Provider>;
};

export default WrapperI18n;
