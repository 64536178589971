import {VtmnSelect} from "@vtmn/react";
import {useContext} from "react";
import {LocaleContext} from "../wrapper-i18n/WrapperI18n";

const LangSwitcher = () => {
  const {locale, setLocale} = useContext(LocaleContext);

  const handleLangChange = item => setLocale(item.target.value);

  return <VtmnSelect onChange={handleLangChange} id="lang-switcher" options={[
    <option key="fr" value="fr">🇫🇷 FR</option>,
    <option key="it" value="it">🇮🇹 IT</option>,
    <option key="es" value="es">🇪🇸 ES</option>,
    <option key="de" value="de">🇩🇪 DE</option>,
    <option key="en" value="en">🇬🇧 EN</option>,
    <option key="nl" value="nl">🇳🇱 NL</option>,
  ]} defaultValue={locale}/>
}

export default LangSwitcher;
