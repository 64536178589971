import {VtmnCard} from "@vtmn/react";
import GrassPicture from "../../assets/form/grass.jpg";
import AsphaltPicture from "../../assets/form/asphalt.jpg";
import WaterPicture from "../../assets/form/water.jpg";
import SandPicture from "../../assets/form/sand.jpg";
import Trampoline240Pic from "../../assets/form/240.jpg";
import Trampoline300Pic from "../../assets/form/300.jpg";
import Trampoline365Pic from "../../assets/form/365.jpg";
import Trampoline420Pic from "../../assets/form/420.jpg";
import Trampoline520Pic from "../../assets/form/520.jpg";
import Placeholder from "../../assets/form/placeholder.svg";
import {useMediaQuery} from "react-responsive";
import {useIntl} from "react-intl";

const Card = ({type, noTitle = false, ...props}) => {
  const intl = useIntl();

  const cards = {
    grass: {title: intl.formatMessage({id: "form.card.grass"}), img: GrassPicture},
    asphalt: {title: intl.formatMessage({id: "form.card.asphalt"}), img: AsphaltPicture},
    water: {title: intl.formatMessage({id: "form.card.water"}), img: WaterPicture},
    sand: {title: intl.formatMessage({id: "form.card.sand"}), img: SandPicture},
    240: {title: intl.formatMessage({id: "form.card.trampoline.240"}), img: Trampoline240Pic},
    300: {title: intl.formatMessage({id: "form.card.trampoline.300"}), img: Trampoline300Pic},
    365: {title: intl.formatMessage({id: "form.card.trampoline.365"}), img: Trampoline365Pic},
    420: {title: intl.formatMessage({id: "form.card.trampoline.420"}), img: Trampoline420Pic},
    520: {title: intl.formatMessage({id: "form.card.trampoline.520"}), img: Trampoline520Pic},
    default: {title: intl.formatMessage({id: "form.card.placeholder"}), img: Placeholder}
  };

  const isTablet = useMediaQuery({query: '(min-width: 600px)'});

  const info = cards[type] ?? cards["default"];

  return <VtmnCard
    title={!noTitle ? info.title : ""}
    headingLevel={2}
    img={<img src={info.img} alt={info.title}/>}
    variant={isTablet ? "top-image" : "side-image"}
    className="items-center tablet:items-stretch"
  >
    {props.children}
  </VtmnCard>;
}

export default Card;
