import {VtmnButton, VtmnModal, VtmnModalActions, VtmnModalDescription, VtmnModalTitle} from "@vtmn/react";
import {useState} from "react";
import Card from "../card/Card";
import {FormattedMessage, useIntl} from "react-intl";

const FloorType = ({handleChange}) => {
  const intl = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return <>
    <h1 className="col-span-full vtmn-typo_display-2"><FormattedMessage id="form.floor.title"/></h1>
    <div className="col-span-4 small:col-span-3 small:col-start-2 medium:col-start-4">
      <Card type="grass">
        <VtmnButton
          className="min-w-full place-self-center"
          size="stretched"
          onClick={toggleModal}>
          <FormattedMessage id="form.floor.cta.soft"/>
        </VtmnButton>
      </Card>
    </div>
    <div className="col-span-4 small:col-span-3">
      <Card type="asphalt">
        <VtmnButton
          className="min-w-full"
          size="stretched"
          name="floor_type" value="asphalt"
          onClick={handleChange}
        >
          <FormattedMessage id="form.floor.cta.hard"/>
        </VtmnButton>
      </Card>
    </div>
    <VtmnModal open={isModalOpen} onClose={toggleModal}>
      <VtmnModalTitle>
        <FormattedMessage id="form.floor.modal.title"/>
      </VtmnModalTitle>
      <VtmnModalDescription>
        <FormattedMessage id="form.floor.modal.description"/>
      </VtmnModalDescription>
      <VtmnModalActions>
        <VtmnButton
          onClick={() => window.open(intl.formatMessage({id: "form.floor.modal.link"}), "_blank").focus()}
          iconRight="external-link-line"
          variant="secondary"
        >
          <FormattedMessage id="form.floor.modal.button.exit"/>
        </VtmnButton>
        <VtmnButton
          onClick={handleChange}
          name="floor_type" value="grass"
          variant="primary"
        >
          <FormattedMessage id="form.floor.modal.button.continue"/>
        </VtmnButton>
      </VtmnModalActions>
    </VtmnModal>
  </>;
}

export default FloorType;
