import {Component} from "react";
import {VtmnButton, VtmnProgressbar} from "@vtmn/react";
import FloorType from "../components/form-steps/FloorType";
import FillType from "../components/form-steps/FillType";
import TrampolineType from "../components/form-steps/TrampolineType";
import Result from "../components/form-steps/Result";
import {FormattedMessage, injectIntl} from "react-intl";

const initialState = {
  step: 0,
  floor_type: "",
  ballast_type: "",
  trampoline_model: "",
  country: "",
  region: "",
};

const maxSteps = 3;

class Form extends Component {
  constructor(props) {
    super(props);
    this.intl = props.intl;
    this.state = initialState;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.step !== this.state.step) {
      window.scrollTo(0, 0);
    }
  }

  nextStep = () => this.setState(state => ({step: Math.min(state.step + 1, maxSteps)}));
  prevStep = () => this.setState(state => ({step: Math.max(state.step - 1, 0)}));
  resetStep = () => this.setState(() => initialState);
  handleChange = (event) => {
    this.setState(() => ({[event.target.name]: event.target.value}));
    this.nextStep();
  };

  getStep = () => {
    switch (this.state.step) {
      case 0:
      default:
        return <FloorType handleChange={this.handleChange}/>;
      case 1:
        return <FillType handleChange={this.handleChange}/>
      case 2:
        return <TrampolineType handleChange={this.handleChange}/>
      case 3:
        return <Result state={this.state}/>
    }
  }

  render() {
    return <div className="max-w-screen min-h-screen py-4 mb-12">
      <div
        className="mx-auto grid grid-cols-4 tablet:grid-cols-8 medium:grid-cols-12 gap-3 tablet:gap-4 medium:gap-6 px-5 tablet:px-6 small:px-10 medium:px-20 large:px-40"
      >
        {this.getStep()}
      </div>
      <div
        className="py-3 bg-white drop-shadow-[0_-1px_2px_rgb(0,0,0,0.1)] fixed flex items-center space-x-4 inset-x-0 bottom-0 px-5 tablet:px-6 small:px-10 medium:px-20 large:px-40"
      >
        <VtmnButton
          variant="ghost"
          size="small"
          iconLeft="chevron-left-fill"
          disabled={this.state.step === 0}
          onClick={this.prevStep}
        >
          <FormattedMessage id="form.actions.back"/>
        </VtmnButton>
        <VtmnProgressbar
          className="flex-auto col-span-full tablet:col-span-6 tablet:col-start-2 small:col-span-6 small:col-start-4"
          value={Math.floor(this.state.step / 3 * 100)}
          loadingText={this.intl.formatMessage({id: "form.actions.progress"})}
        />
        <VtmnButton
          variant="ghost"
          size="small"
          iconRight="refresh-fill"
          disabled={this.state.step === 0}
          onClick={this.resetStep}
        >
          <FormattedMessage id="form.actions.reset"/>
        </VtmnButton>
      </div>
    </div>
  }
}

export default injectIntl(Form);
