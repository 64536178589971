import {VtmnButton} from "@vtmn/react";
import HomepageCover from "../assets/hp-trampoline.jpg";
import {useNavigate} from "react-router-dom";
import {useCallback} from "react";
import {FormattedMessage} from "react-intl";

function Home() {
  const navigate = useNavigate();
  const handleOnClick = useCallback(() => navigate("/form"), [navigate]);

  return (
    <section className="small:grid small:grid-cols-6 min-h-screen">
      <div
        className="bg-gray-50 px-4 tablet:px-6 small:px-10 medium:px-20 large:px-40 pt-4 medium:pt-20 small:col-span-4">
        <div className="mx-auto max-w-xl text-center small:text-left">
          <h1 className="vtmn-typo_display-1 break-words">
            <FormattedMessage id="home.title" values={{blue: (...chunks) => <span className="text-primary block">{chunks}</span>}}/>
          </h1>

          <p className="text-gray-500 mt-8">
            <FormattedMessage id="home.description" />
          </p>

          <div className="flex flex-wrap justify-center small:justify-start -m-2 py-8">
            <VtmnButton onClick={handleOnClick} className="m-2"><FormattedMessage id="home.cta.form" /></VtmnButton>
            <VtmnButton className="m-2" variant="secondary"><FormattedMessage id="home.cta.more"/></VtmnButton>
          </div>
        </div>
      </div>

      <img
        alt="Trampoline Cover"
        src={HomepageCover}
        className="h-56 w-full object-cover object-bottom tablet:h-2/5 small:h-full small:col-span-2 small:object-[30%] medium:object-[20%]"
      />
    </section>
  );
}

export default Home;
